<template>
  <cp-filter
    ref="cpFilter"
    name="auditLog"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md filterBlock">
        <cp-select
          name="operator"
          :label="$t('auditLog.label.filter.operator')"
          :options="operatorOptions"
          :value="'null'"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-select
          name="issuer"
          :label="$t('auditLog.label.filter.issuer')"
          :options="issuerOptions"
          :value="'null'"
          @input="handleIssuerChange"
        />
      </div>

      <div class="col-md filterBlock">
        <cp-select
          name="token"
          :label="$t('auditLog.label.filter.token')"
          :options="tokenOptions"
          :value="'null'"
        />
      </div>

      <div class="col-md-4 filterBlock">
        <cp-date-range
          v-validate="'date_range'"
          name="from-created-at"
          :label="$t('auditLog.label.filter.dateRange')"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpDateRange from '~/components/common/standalone-components/inputs/cp-date-range';
import CpFilter from '~/components/common/cp-filter';
import filterParseForApi from '~/utilities/investorFilterParseForApi';

export default {
  name: 'AuditLogFilter',
  components: {
    CpSelect,
    CpDateRange,
    CpFilter,
  },
  props: {
  },
  data() {
    return {
      operatorsList: [],
      issuersList: [],
      tokensList: [],
    };
  },
  computed: {
    ...mapGetters('auditLog', ['getIssuerOptions', 'getOperatorOptions', 'getTokenOptions']),
    operatorOptions() {
      return [{ value: null, text: 'All' }, ...(this.getOperatorOptions || [])];
    },
    issuerOptions() {
      return [{ value: null, text: 'All' }, ...(this.getIssuerOptions || [])];
    },
    tokenOptions() {
      return [{ value: null, text: 'All' }, ...(this.getTokenOptions || [])];
    },
  },
  async created() {
    await this.getOperatorList();
    await this.getIssuerList();
    await this.getTokenList();
  },
  methods: {
    ...mapActions('auditLog', ['getOperatorList', 'getIssuerList', 'getTokenList', 'getTokenListByIssuerId']),
    updateFilter() {
      this.$emit('updateFilter', this.getActiveFilters());
    },
    getActiveFilters() {
      return filterParseForApi(this.$refs.cpFilter.getDefaultValue);
    },
    handleIssuerChange(issuerId) {
      if (issuerId === null) {
        return this.getTokenList();
      }
        return this.getTokenListByIssuerId({ issuerId });
    },
  },
};
</script>

<style>
  .filterBlock {
    margin: 0 2%;
    min-width: 25%;
  }
</style>
