<template>
  <cp-confirm-modal
    ref="viewAuditLog"
    size="lg"
    :title="`Audit Log #${currentItem.id}`"
    ok-only
  >
    <template slot="slot-extended">
      <div class="card border-light mx-4 mt-4">
        <b-card>
          <strong> ID: </strong>{{ currentItem.id }}
        </b-card>
        <b-card>
          <strong> Method: </strong>{{ currentItem.method }}
        </b-card>
        <b-card>
          <strong> URL: </strong>{{ currentItem.url }}
        </b-card>
        <b-card>
          <strong> Body: </strong>{{ currentItem.body }}
        </b-card>
        <b-card>
          <strong> Created: </strong>{{ currentItem.createdAt | standardDateFilter }}
        </b-card>
      </div>
    </template>
  </cp-confirm-modal>
</template>

<script>
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'ViewAuditLog',
  components: {
    CpConfirmModal,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    transactionInfo: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
