var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"audit-log-table-container"},[_c('cp-table',{ref:"cpTable",attrs:{"default-sort-by":"createdAt","default-sort-direction":"desc","filters":_vm.filters,"get-data-action":"auditLog/getAuditLogsList","fields":_vm.tableFields,"search-value":_vm.searchQueryParam,"table-title":"Audit Log"},on:{"tableDataUpdated":_vm.onTableDataUpdated},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("standardDateFilter")(rowData.item.createdAt))+" ")])]}},{key:"operatorName",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getOperatorName(rowData.item))+" ")])]}},{key:"domain",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getDomain(rowData.item))+" ")])]}},{key:"tokenName",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getTokenName(rowData.item))+" "+_vm._s(_vm.getTokenBlockchainNetwork(rowData.item))+" ")])]}},{key:"affectedArea",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getAffectedArea(rowData.item))+" ")])]}},{key:"actionType",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getActionType(rowData.item))+" ")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.view')},on:{"click":function($event){return _vm.viewAuditLog(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-eye"}),_vm._v(" "+_vm._s(_vm.$t('common.button.view'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }